<template>
    <div
      v-if="
        ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.tesouraria == 'S') &&
          $store.state.fastPermissoes.ativo == 'S' &&
          $store.state.fastPlataformaModulos.modulo_secretaria
      "
    >
      <div class="fd-app-wrap">
        <div class="container">
          <!-- breadcrumb -->
          <nav>
    
            <ul class="fd-app-breadcrumb">
              <li class="fd-app-breadcrumb-item">
                <a
                  href="/minhas-plataformas"
                  class="aluno_font_color"
                  @click.prevent="$router.push('/minhas-plataformas')"
                >Minhas Plataformas</a>
              </li>
              <li class="fd-app-breadcrumb-item">
                <a
                  class="aluno_font_color"
                  href="#"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
                >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
              </li>
              <li class="fd-app-breadcrumb-item">
                <a
                  class="aluno_font_color"
                  href="#"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
                >Modo tesouraria</a>              
              </li>
              <li class="fd-app-breadcrumb-item active">
                <a
                  class="aluno_font_color"
                  href="#"
                >Relatórios - Registro Inadimplência</a>
              </li>
            </ul>
          </nav>
  
          <!-- /breadcrumb -->
        </div>
        <div class="fd-mode-adm-content mt-5 container">
          
          <!-- Conteúdo Principal Relatório -->
          <transition name="slide-fade">
            <div v-if="fastNavegacao.exibirRelatorio">
              <section class="fd-app-welcome text-left">
                <h2><b>Relatório Registro Inadimplência</b></h2>
                <img
                  class="mb-3"
                  :src="require('@/assets/images/form-effect-01.png')"
                  style="max-height: 16px"
                >
              </section>
                <!-- <div class="d-flex">
                  <div class="align-self-start mr-auto mb-2">
                    <button v-if="this.fastRegInadimplenciaRel.filtroEscolhido.idInvoice"
                      @click="fastRegInadimplenciaRel.filtroEscolhido.idInvoice = '', getRegInadimplencia()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Invoice: {{ this.fastRegInadimplenciaRel.filtroEscolhido.idInvoice }} <b-icon icon="x-lg" font-scale="0.7"
                        class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastRegInadimplenciaRel.filtroEscolhido.idMatriculador "
                      @click="fastRegInadimplenciaRel.filtroEscolhido.idMatriculador = '', fastRegInadimplenciaRel.filtros.usuarioGerou = '', getRegInadimplencia()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Matriculado por: {{ this.fastRegInadimplenciaRel.filtros.usuarioGerou }} <b-icon icon="x-lg" font-scale="0.7"
                        class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastRegInadimplenciaRel.filtroEscolhido.nomeAluno"
                      @click="fastRegInadimplenciaRel.filtroEscolhido.nomeAluno = '', getRegInadimplencia()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Aluno: {{ this.fastRegInadimplenciaRel.filtroEscolhido.nomeAluno }} <b-icon icon="x-lg" font-scale="0.7"
                        class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastRegInadimplenciaRel.filtroEscolhido.nomeResponsavel"
                      @click="fastRegInadimplenciaRel.filtroEscolhido.nomeResponsavel = '', getRegInadimplencia()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Resp.: {{ this.fastRegInadimplenciaRel.filtroEscolhido.nomeResponsavel }} <b-icon icon="x-lg"
                        font-scale="0.7" class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastRegInadimplenciaRel.filtroEscolhido.statusPago"
                      @click="fastRegInadimplenciaRel.filtros.status = '', alteraFiltroStatus(), getRegInadimplencia()"
                      type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      <span>Status: Pago </span> 
                      <b-icon icon="x-lg" font-scale="0.7" class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastRegInadimplenciaRel.filtroEscolhido.statusCancelado"
                      @click="fastRegInadimplenciaRel.filtros.status = '', alteraFiltroStatus(), getRegInadimplencia()"
                      type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      <span>Status: Cancelado </span> 
                      <b-icon icon="x-lg" font-scale="0.7" class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastRegInadimplenciaRel.filtroEscolhido.statusVencido"
                      @click="fastRegInadimplenciaRel.filtros.status = '', alteraFiltroStatus(), getRegInadimplencia()"
                      type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      <span>Status: Vencido </span> 
                      <b-icon icon="x-lg" font-scale="0.7" class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastRegInadimplenciaRel.filtroEscolhido.statusPendente"
                      @click="fastRegInadimplenciaRel.filtros.status = '', alteraFiltroStatus(), getRegInadimplencia()"
                      type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      <span>Status: Pendente </span> 
                      <b-icon icon="x-lg" font-scale="0.7" class="mb-1"></b-icon>
                    </button>
                  </div>
                </div> -->
              <section>
                <div class="row">
                  <form class="form col-sm-12 col-md-12 col-lg-4">
                    <label class="text-secondary mb-0 mt-2"><small>Plataforma:</small></label>
                    <select
                      class="form-control form-control-sm"
                      v-model="fastRegInadimplenciaRel.filtroEscolhido.id_plataforma"
                     >
                      <option>Todas</option>
                      <option v-for="(plataforma, index) in fastRegInadimplenciaRel.filtros.plataformas" :key="index"
                        :value="plataforma.id_plataforma">
                        {{ plataforma.nome_plataforma }}
                      </option>
                    </select>
                    <label class="text-secondary mb-0 mt-2"><small>Invoice:</small></label>
                    <input
                    type="text"
                    v-model="fastRegInadimplenciaRel.filtroEscolhido.id_invoice"
                    class="form-control form-control-sm"
                    />
                    <label class="text-secondary mb-0 mt-2"><small>Status Bloqueio:</small></label>
                    <select
                    :class="fastRegInadimplenciaRel.filtros.status_bloqueio ? 'form-control form-control-sm border border-primary text-primary' : 'form-control form-control-sm'"
                    v-model="fastRegInadimplenciaRel.filtros.status_bloqueio" @change="alteraFiltroStatus()">
                      <option value="">Todos</option>
                      <option value="true">
                        Bloqueado
                      </option>
                      <option value="false">
                        Desbloqueado
                      </option>
                    </select>
                    </form>
                  <form class="form col-sm-12 col-md-12 col-lg-4">
                    <label class="text-secondary mb-0 mt-2"><small>Aluno:</small></label>
                    <input
                    type="text"
                    v-model="fastRegInadimplenciaRel.filtroEscolhido.nome_aluno"
                    class="form-control form-control-sm"
                    />
                    <label class="text-secondary mb-0 mt-2"><small>Responsável:</small></label>
                    <input
                    type="text"
                    v-model="fastRegInadimplenciaRel.filtroEscolhido.nome_responsavel"
                    class="form-control form-control-sm"
                    />
                    <!-- <label class="text-secondary mb-0 mt-2"><small>Usuário que gerou:</small></label>
                    <div style="position: relative">
                      <input 
                        :class="fastRegInadimplenciaRel.filtros.usuarioGerou.length ? 'form-control form-control-sm border border-primary text-primary' : 'form-control form-control-sm'"
                        v-model="fastRegInadimplenciaRel.filtros.usuarioGerou" 
                        type="text" 
                        :list="fastRegInadimplenciaRel.filtros.datalistId"
                        @input="filtraPessoaSugestao('usuarioGerou', 'usuariosGerou')"
                        @keyup.esc="selecionaPessoaSugerida('')"
                      >
                      <ul v-if="fastRegInadimplenciaRel.filtros.usuariosGerou.length" class="sugestao-lista">
                        <li 
                        class="sugestao-item"
                        v-for="usuario in fastRegInadimplenciaRel.filtros.usuariosGerou" 
                        :key="usuario.id_usuario" 
                        @click="selecionaPessoaSugerida(usuario)">
                          {{ usuario.nome_razao }}
                        </li>
                      </ul>
                    </div> -->
                  </form>
                  <form class="form col-sm-12 col-md-12 col-lg-4">
                    <label class="text-primary mb-1 mt-2 mx-sm-1 mx-md-1 row">
                      <small class="text-primary ml-0 pl-0 col-8">Data Bloqueio:</small>
                      <small
                      class="col-4 text-right mr-0 pr-0"
                      role="button"
                      v-if="fastRegInadimplenciaRel.filtros.data_registro_bloqueio_inicio || fastRegInadimplenciaRel.filtros.data_registro_bloqueio_fim" 
                      @click="fastRegInadimplenciaRel.filtros.data_registro_bloqueio_inicio = '', fastRegInadimplenciaRel.filtros.data_registro_bloqueio_fim = ''"
                      >Limpar <b-icon icon="x-circle" scale="1.1" variant="danger"></b-icon></small>
                    </label>
                    <div class="row mx-sm-1 mx-md-1">
                      <b-form-datepicker 
                      :class="validaDataFiltro(fastRegInadimplenciaRel.filtros.data_registro_bloqueio_inicio, fastRegInadimplenciaRel.filtros.data_registro_bloqueio_fim) ? 'col-6 date-picker-filtro ' : 'col-6 date-picker-filtro border-danger text-red'"
                      @input="
                      validaDataFiltro(fastRegInadimplenciaRel.filtros.data_registro_bloqueio_inicio, fastRegInadimplenciaRel.filtros.data_registro_bloqueio_fim, true), 
                      fastRegInadimplenciaRel.filtros.data_vencimento_inicio = '', 
                      fastRegInadimplenciaRel.filtros.data_vencimento_fim = ''"
                      v-model="fastRegInadimplenciaRel.filtros.data_registro_bloqueio_inicio"
                      locale="pt-BR"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                      label-no-date-selected="Data inicial"
                      size="sm"
                      placeholder="Data inicial"
                      label-help="Use o cursor para selecionar"
                      ></b-form-datepicker>
                      <b-form-datepicker 
                      :class="validaDataFiltro(fastRegInadimplenciaRel.filtros.data_registro_bloqueio_inicio, fastRegInadimplenciaRel.filtros.data_registro_bloqueio_fim) ? 'col-6 date-picker-filtro ' : 'col-6 date-picker-filtro border-danger text-red'"
                      @input="
                      validaDataFiltro(fastRegInadimplenciaRel.filtros.data_registro_bloqueio_inicio, fastRegInadimplenciaRel.filtros.data_registro_bloqueio_fim, true), 
                      fastRegInadimplenciaRel.filtros.data_vencimento_inicio = '', 
                      fastRegInadimplenciaRel.filtros.data_vencimento_fim = ''"
                      v-model="fastRegInadimplenciaRel.filtros.data_registro_bloqueio_fim"
                      locale="pt-BR"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                      label-no-date-selected="Data final"
                      size="sm"
                      placeholder="Data final"
                      label-help="Use o cursor para selecionar"
                      ></b-form-datepicker>
                    </div>
                    <label class="text-primary mb-1 mt-2 mx-sm-1 mx-md-1 row">
                      <small class="text-primary ml-0 pl-0 col-8">Data Desbloqueio:</small>
                      <small
                      class="col-4 text-right mr-0 pr-0"
                      role="button"
                      v-if="fastRegInadimplenciaRel.filtros.data_registro_desbloqueio_inicio || fastRegInadimplenciaRel.filtros.data_registro_desbloqueio_fim" 
                      @click="fastRegInadimplenciaRel.filtros.data_registro_desbloqueio_inicio = '', fastRegInadimplenciaRel.filtros.data_registro_desbloqueio_fim = ''"
                      >Limpar <b-icon icon="x-circle" scale="1.1" variant="danger"></b-icon></small>
                    </label>
                    <div class="row mx-sm-1 mx-md-1">
                      <b-form-datepicker 
                      :class="validaDataFiltro(fastRegInadimplenciaRel.filtros.data_registro_desbloqueio_inicio, fastRegInadimplenciaRel.filtros.data_registro_desbloqueio_fim) ? 'col-6 date-picker-filtro ' : 'col-6 date-picker-filtro border-danger text-red'"
                      @input="validaDataFiltro(fastRegInadimplenciaRel.filtros.data_registro_desbloqueio_inicio, fastRegInadimplenciaRel.filtros.data_registro_desbloqueio_fim, true), 
                      fastRegInadimplenciaRel.filtros.data_vencimento_inicio = '', 
                      fastRegInadimplenciaRel.filtros.data_vencimento_fim = ''"
                      v-model="fastRegInadimplenciaRel.filtros.data_registro_desbloqueio_inicio"
                      locale="pt-BR"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                      label-no-date-selected="Data inicial"
                      size="sm"
                      placeholder="Data inicial"
                      label-help="Use o cursor para selecionar"
                      ></b-form-datepicker>
                      <b-form-datepicker 
                      :class="validaDataFiltro(fastRegInadimplenciaRel.filtros.data_registro_desbloqueio_inicio, fastRegInadimplenciaRel.filtros.data_registro_desbloqueio_fim) ? 'col-6 date-picker-filtro ' : 'col-6 date-picker-filtro border-danger text-red'"
                      @input="validaDataFiltro(fastRegInadimplenciaRel.filtros.data_registro_desbloqueio_inicio, fastRegInadimplenciaRel.filtros.data_registro_desbloqueio_fim, true), 
                      fastRegInadimplenciaRel.filtros.dataVencimentoStart = '', 
                      fastRegInadimplenciaRel.filtros.dataVencimentoEnd = ''"
                      v-model="fastRegInadimplenciaRel.filtros.data_registro_desbloqueio_fim"
                      locale="pt-BR"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                      label-no-date-selected="Data final"
                      size="sm"
                      placeholder="Data final"
                      label-help="Use o cursor para selecionar"
                      ></b-form-datepicker>
                    </div>
                  </form>
                </div>
                <div class="row">
                  <div class="text-left col-lg-4 col-md-12 col-sm-12 mt-4 mb-3">
                      <label class="text-secondary mb-0 mx-1 row">
                      <small class="pl-0 ml-0 pl-0 col-8"><b>Vencimento:</b></small>
                      <small
                      class="col-4 text-right mr-0 pr-0"
                      role="button"
                      v-if="fastRegInadimplenciaRel.filtros.data_vencimento_inicio || fastRegInadimplenciaRel.filtros.data_vencimento_fim" 
                      @click="fastRegInadimplenciaRel.filtros.data_vencimento_inicio = '', fastRegInadimplenciaRel.filtros.data_vencimento_fim = ''"
                      >Limpar <b-icon icon="x-circle" scale="1.1" variant="danger"></b-icon></small>
                    </label>
                      <div class="d-flex">
                        <b-form-datepicker 
                        class="align-self-start"
                        size="sm"
                        v-model="fastRegInadimplenciaRel.filtros.data_vencimento_inicio" 
                        @input="getRegInadimplenciaRel()"
                        locale="pt-BR"
                        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                        label-no-date-selected="Selecionar data"
                        placeholder="Selecionar data"
                        label-help="Use o cursor para selecionar"
                        ></b-form-datepicker>
                        <small class="align-self-start pt-1 px-2">até</small>
                        <b-form-datepicker 
                        class="align-self-start"
                        size="sm"
                        v-model="fastRegInadimplenciaRel.filtros.data_vencimento_fim"
                        @input="getRegInadimplenciaRel()"
                        locale="pt-BR"
                        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                        label-no-date-selected="Selecionar data"
                        placeholder="Selecionar data"
                        label-help="Use o cursor para selecionar"
                        ></b-form-datepicker>
                      </div>              
                  </div>
                  <div class="text-right col-lg-8 col-md-12 col-sm-12 mb-3 mt-5">
                    <b-button @click="exportaPlanilha()" variant="outline-primary btn-sm text-capitalize rounded mr-3">Exportar</b-button>  
                    <b-button @click="limpaFiltros()" variant="custom-outline-primary btn-sm text-capitalize rounded mr-3">Limpar Filtros</b-button>
                    <b-button @click="getRegInadimplenciaRel()" variant="custom-primary btn-sm text-capitalize rounded">Filtrar</b-button>                       
                  </div>
                </div>
              </section>

                <div class="row">
                  <div class="col-12 table-responsive">
                    <!--tabela oculta sem paginação para exportar-->
                    <table id="relatorio" class="table d-none">
                      <thead>
                        <tr>
                          <th>
                            <small>Vencimento</small>
                          </th>
                          <th>
                            <small>Recebimento</small>
                          </th>
                          <th>
                            <small>Dias de Atraso</small>
                          </th>
                          <th>
                            <small>Invoice</small>
                          </th>
                          <th>
                            <small>Parcela</small>
                          </th>
                          <th>
                            <small>Aluno</small>
                          </th>
                          <th>
                            <small>Valor Parcela</small>
                          </th>
                          <th>
                            <small>Valor Recebido</small>
                          </th>
                          <th>
                            <small>Status Bloqueio</small>
                          </th>
                          <th>
                            <small>Status Pgto.</small>
                          </th>
                          <th>
                            <small>Ações</small>
                          </th>
                        </tr>
                      </thead>
                  <tbody>
                    <tr
                      v-for="(e, index) in fastRegInadimplenciaRel.filtro"
                      :key="index"
                    >
                      <td>
                        <small>{{ formataDataT(e.data_vencimento) }}</small>                                  
                      </td>
                      <td>
                        <small>{{ formataDataT(e.data_pagamento) }}</small>                                  
                      </td>
                      <td>
                        <small v-if="!e.pago && !e.cancelado && new Date(e.data_vencimento) < new Date() && calculaDiasAtraso(e.data_vencimento) > 0">{{ calculaDiasAtraso(e.data_vencimento) }}</small>     
                        <small v-else>-</small>                            
                      </td>
                      <td>
                          <small>{{ e.id_invoice }}</small>                               
                      </td>
                      <td>
                        <small> {{ e.parcela }}/{{ e.nr_parcelas }} </small>                             
                      </td>
                      <td>
                        <small> {{ e.nome_aluno }} </small>                                 
                      </td>
                      <td>
                        <small>{{ formataPreco(e.valor_parcela) }}</small>                                  
                      </td>
                      <td>
                        <small v-if="e.valor_pago && e.pago">{{ formataPreco(e.valor_pago) }}</small> 
                        <small v-else>{{ formataPreco(0)}} </small>                                 
                      </td>
                      <td>
                        <span v-if="e.acesso_bloqueado"><small>Bloqueado</small> </span>
                        <span v-else><small>Desbloqueado</small></span>
                      </td>
                      <td>
                        <span v-if="e.cancelado"><small>Cancelado</small> </span>
                        <span v-else-if="e.pago"><small>Pago</small> </span>
                        <span v-else-if="!e.pago && !e.cancelado && new Date(e.data_vencimento) < new Date()"><small>Vencido</small></span>
                        <span v-else-if="!e.pago && !e.cancelado && new Date(e.data_vencimento) >= new Date()"><small>Pendente</small></span>
                      </td>
                      <td>
                        <small v-if="e.log_pagto_auditoria">{{ e.log_pagto_auditoria }}</small>
                        <small v-else>-</small>
                      </td>
                    </tr>
                  </tbody>
                    </table>
                    <table class="table table-sm text-nowrap table-hover">
                      <thead class="thead-dark" v-if="fastRegInadimplenciaRel.loading">
                        <tr>
                          <th
                            colspan="12"
                            class="text-center"
                          >
                          <b-icon icon="gear-fill" animation="spin"/> Carregando 
                          </th>
                        </tr>
                      </thead>
                      <thead class="thead-dark" v-if="!fastRegInadimplenciaRel.loading">
                        <tr>
                          <th class="pl-3 pt-2">
                            <b-form-checkbox v-model="fastRegInadimplenciaRel.selectAll"/>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRegInadimplenciaRel.orderBy.vencimento !== 0 }" @click="orderByColumn('vencimento')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                              Vencimento&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRegInadimplenciaRel.orderBy.vencimento === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRegInadimplenciaRel.orderBy.vencimento === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRegInadimplenciaRel.orderBy.recebimento !== 0 }" @click="orderByColumn('recebimento')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                              Recebimento&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRegInadimplenciaRel.orderBy.recebimento === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRegInadimplenciaRel.orderBy.recebimento === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRegInadimplenciaRel.orderBy.dias_de_atraso !== 0 }" @click="orderByColumn('dias_de_atraso')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                              Dias de Atraso&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRegInadimplenciaRel.orderBy.dias_de_atraso === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRegInadimplenciaRel.orderBy.dias_de_atraso === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRegInadimplenciaRel.orderBy.invoice !== 0 }" @click="orderByColumn('invoice')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                              Invoice&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRegInadimplenciaRel.orderBy.invoice === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRegInadimplenciaRel.orderBy.invoice === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small class="font-weight-bold d-flex pb-1 justify-content-center">
                              Parcela
                              <div class="d-flex flex-column"></div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRegInadimplenciaRel.orderBy.aluno !== 0 }" @click="orderByColumn('aluno')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Aluno&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRegInadimplenciaRel.orderBy.aluno === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRegInadimplenciaRel.orderBy.aluno === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRegInadimplenciaRel.orderBy.valor_parcela !== 0 }" @click="orderByColumn('valor_parcela')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Valor Parcela&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRegInadimplenciaRel.orderBy.valor_parcela === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRegInadimplenciaRel.orderBy.valor_parcela === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRegInadimplenciaRel.orderBy.valor_recebido !== 0 }" @click="orderByColumn('valor_recebido')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Valor Recebido&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRegInadimplenciaRel.orderBy.valor_recebido === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRegInadimplenciaRel.orderBy.valor_recebido === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRegInadimplenciaRel.orderBy.status_bloqueio !== 0 }" @click="orderByColumn('status_bloqueio')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Status Bloq.&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRegInadimplenciaRel.orderBy.status_bloqueio === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRegInadimplenciaRel.orderBy.status_bloqueio === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRegInadimplenciaRel.orderBy.status_pagamento !== 0 }" @click="orderByColumn('status_pagamento')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Status Pgto.&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRegInadimplenciaRel.orderBy.status_pagamento === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRegInadimplenciaRel.orderBy.status_pagamento === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small class="font-weight-bold d-flex pb-1 justify-content-center">
                              Boleto
                              <div class="d-flex flex-column"></div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small class="font-weight-bold d-flex pb-1 justify-content-center">
                              Log
                              <div class="d-flex flex-column"></div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small class="font-weight-bold d-flex pb-1 justify-content-center">
                              Ações
                              <div class="d-flex flex-column"></div>
                            </small>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="fastRegInadimplenciaRel.loading">
                    <tr>
                      <td
                        colspan="12"
                        class="text-center"
                      >
                      <b-icon icon="gear-fill" animation="spin"/> Carregando 
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else-if="fastRegInadimplenciaRel.filtro.length">
                    <tr
                      v-for="(e, index) in fastRegInadimplenciaRel.filtro"
                      :key="index"
                    >
                      <td class="pl-3 pt-2"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                      >
                        <b-form-checkbox role="button" type="checkbox" v-model="fastRegInadimplenciaRel.selectedItems" :value="e.id_invoice + '-' + e.parcela"/>
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        @click="exibeDetalhes(e)"
                      >
                        <small>{{ formataDataT(e.data_vencimento) }}</small>                                  
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}" 
                        role="button"
                        @click="exibeDetalhes(e)"
                      >
                        <small>{{ formataDataT(e.data_pagamento) }}</small>                                  
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        @click="exibeDetalhes(e)"
                      >
                        <small v-if="!e.pago && !e.cancelado && new Date(e.data_vencimento) < new Date() && calculaDiasAtraso(e.data_vencimento) > 0">{{ calculaDiasAtraso(e.data_vencimento) }}</small>     
                        <small v-else>-</small>                            
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        @click="exibeDetalhes(e)"  
                      >
                          <small>{{ e.id_invoice }}</small>                               
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        @click="exibeDetalhes(e)"
                      >
                        <small>{{ e.parcela }}/{{ e.nr_parcelas }}</small>                             
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        @click="exibeDetalhes(e)"
                      >
                        <small>{{ e.nome_aluno }}</small>                                 
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        @click="exibeDetalhes(e)"
                      >
                        <small>R$ {{ formataPreco(e.valor_parcela) }}</small>                                  
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        @click="exibeDetalhes(e)"
                      >
                        <small v-if="e.valor_pago && e.pago">R$ {{ formataPreco(e.valor_pago) }}</small> 
                        <small v-else>R$ 0,00</small>                                 
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        @click="exibeDetalhes(e)"
                      >
                        <small v-if="e.acesso_bloqueado" class="text-danger">Bloqueado</small> 
                        <small v-else class="text-success">Desbloqueado</small>                                 
                      </td>
                      <td class="text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        @click="exibeDetalhes(e)"
                      >
                        <span v-if="e.cancelado" class="text-secondary"><small>Cancelado</small> </span>
                        <span v-else-if="e.pago" class="text-success"><small>Pago</small> </span>
                        <span v-else-if="!e.pago && !e.cancelado && new Date(e.data_vencimento) < new Date()"><small class="text-danger">Vencido</small></span>
                        <span v-else-if="!e.pago && !e.cancelado && new Date(e.data_vencimento) >= new Date()" class="text-primary"><small>Pendente</small> </span>
                      </td>
                      <td class="align-middle text-center pl-2"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                      >
                        <a title="ABRIR BOLETO" :href="e.path_boleto" target="_blank" class="text-dark mr-2"><b-icon icon="file-earmark-pdf" font-scale="1" ></b-icon></a>
                        <a title="COPIAR URL BOLETO" href="#" @click.prevent="copyURLBoleto(e.path_boleto); exibeToasty('Boleto copiado para a Área de Transferência!','success')" target="_blank" class="text-dark"><b-icon icon="back" font-scale="1"></b-icon></a>
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                      >
                        <a v-if="e.log_pagto_auditoria" role="button" title="LOG PAGAMENTO" @click.prevent="exibeModal(e, 'log_pagto')" class="text-primary"><u>Ver</u></a>
                        <span v-else>-</span>
                      </td>
                      <td class="text-center">
                        <b-dropdown size="sm" variant="light rounded">
                          <b-dropdown-item @click.prevent="exibeDetalhes(e)">Detalhes</b-dropdown-item>
                          <!-- <b-dropdown-item disabled @click.prevent="exibeDetalhes(e)">Renegociação</b-dropdown-item>
                          <b-dropdown-item @click.prevent="exibeModal(e, 'comissoes')">Comissões</b-dropdown-item> -->
                        </b-dropdown>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td
                        colspan="12"
                        class="text-center"
                      >
                        Nenhuma parcela encontrada
                      </td>
                    </tr>
                  </tbody>
                    </table>
                  </div>
                  <div class="col-12 text-right mt-4">
                      <div class="d-inline">
                        <h6 class="font-weight-bold d-inline">Valor Total: </h6>
                        <h5 class="d-inline"><b-badge variant="primary">R$ {{ formataPreco(totalParcelaSelected) }}</b-badge></h5>
                      </div>
                      <div class="mt-2 mb-2">
                        <h6 class="font-weight-bold d-inline">Valor Recebido: </h6>
                        <h5 class="d-inline"><b-badge variant="success">R$ {{ formataPreco(totalParcelaPagoSelected) }}</b-badge></h5>
                      </div>
                  </div>
                  <div class="align-self-center mx-auto text-center">
                    <b-pagination
                      v-model="fastPaginacao.fastPageIndex"
                      :total-rows="fastPaginacao.fastTotalResults"
                      :per-page="fastPaginacao.fastPageSize"
                      @change="pagRegInadimplenciaRel"
                    ></b-pagination>
                  </div>
                </div>
            </div>
          </transition>
          <!-- /Conteúdo Principal Relatório -->

          <!-- Detalhes - Ações -->
          <transition name="slide-fade">
            <div v-if="fastNavegacao.exibirDetalhes">
              <DetalhesRecebivel 
                :id_plataforma="fastInvoiceFiltrosDetalhes.idPlataforma" 
                :id_invoice="fastInvoiceFiltrosDetalhes.idInvoice" 
                :parcela="fastInvoiceFiltrosDetalhes.parcela"
                tipo="detalhes"
              />
            </div>
          </transition>
          <!-- /Detalhes - Ações -->

          <div class="mt-5 mb-5">
            <a
              v-if="fastNavegacao.exibirRelatorio" 
              id="btn_voltar_modo_admin"
              class="btn btn-red-hollow"
              :href="
                '/plataforma/' + $route.params.id_plataforma + '/tesouraria' 
              "
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
            >
              <b-icon-arrow-return-left />
              Voltar
            </a>
            <a
              v-if="fastNavegacao.exibirDetalhes"
              id="btn_voltar_modo_admin"
              class="btn btn-red-hollow"
              :href="
                '/plataforma/' + $route.params.id_plataforma + '/tesouraria' 
              "
              @click.prevent="alteraFastNavegacao('exibirRelatorio')"
            >
              <b-icon-arrow-return-left />
              Voltar
            </a>
          </div>

        </div>

        <!--modals-->
        <modal
          name="modalVerLog"
          :scrollable="true"
          height="auto"
          :shift-y="0.3"
          :focus-trap="true"
          :adaptive="true"
        >
          <div class="row p-4">
            <div class="col-10 pl-4 pr-4">
              <h4>Log / Motivo</h4>
            </div>
            <div class="col-2 pl-4 pr-4 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalVerLog')"
              >
                <b-icon-arrow-return-left />
              </a>
            </div>
            <div class="col-12 p-4">
              <div class="row">
                <div class="col-12 mt-4 mb-4">

                  <p class="text-info">
                    {{ fastRegInadimplenciaRel.modal.logRecebivel }}
                  </p>

                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12">
                  <button class="btn btn-secondary mr-2" @click="hideModal('modalVerLog'); fastLinkClipboard = false">
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </modal>
      </div>
    </div>
  </template>
  
  <script>
  import methods from "@/methods";
  import DetalhesRecebivel from "../components/DetalhesRecebivel.vue"
  import * as XLSX from 'xlsx';
  
  export default {
    name: "HomeInternoTesourariaRelatoriosRegistroInadimplencia",
    components: {
      DetalhesRecebivel
    },
    mixins: [methods],
    data: function () {
      return {
        modalWidthSm: this.isMobile() ? "80%" : "30%",
        modalWidthMd: this.isMobile() ? "90%" : "50%",
        modalWidthLg: this.isMobile() ? "100%" : "90%",
        
        fastLinkClipboard: false,
        fastNavegacao: {
          exibirRelatorio : true,
          exibirDetalhes : false,
        },
        fastRegInadimplenciaRel: {
          loading: true,
          filtro: [],
          filtroEscolhido : {
            acesso_bloqueado: false,
            acrescimo: '',
            acrescimo_invoice: '',
            ativo: false,
            cancelado: '',
            cancelado_invoice: '',
            carnet_id: '',
            charge_id: '',
            cpf_responsavel: '',
            cpf_aluno: '',
            data_cancelamento: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
            data_cancelamento_invoice: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
            data_emissao: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
            data_registro: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
            data_pagamento: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
            data_registro_bloqueio: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
            data_registro_desbloqueio: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
            data_registro_inadimplencia: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
            data_renegociacao: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
            data_vencimento: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
            data_vencimento_inadimplencia: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
            desconto: '',
            desconto_total: '',
            id_cento_custo: '',
            id_conta_banco: '',
            id_gateway: '',
            id_invoice: '',
            id_matricula: '',
            id_plataforma: this.$route.params.id_plataforma,
            id_pessoa_aluno: '',
            id_plano_conta: '',
            id_pessoa_responsavel: '',
            id_usuario_acao: '',
            id_usuario_baixa: '',
            id_usuario_cancelamento: '',
            id_usuario_cancelamento_invoice: '',
            id_usuario_gerou: '',
            inadimplente: false,
            log_pagto_auditoria: '',
            motivo_cancelamento: '',
            motivo_cancelamento_invoice: '',
            nome_aluno: '',
            nome_responsavel: '',
            nr_parcelas: '',
            obs: '',
            outras_taxas: '',
            pago: false,
            parcela: '',
            path_boleto: '',
            path_comprovante_pagamento: '',
            subtotal: '',
            taxa_boleto: '',
            taxa_cartao: '',
            total: '',
            total_invoice: '',
            valor_devido_inadimplencia: '',
            valor_pago: '',
            valor_parcela: '',
          },
          filtros : {
            plataformas: [],
            status_bloqueio: '',
            status_pagamento: '',
            usuariosGerou : [],
            usuariosCancelou : [],
            usuarioGerou: '',
            usuarioCancelou: '',
            datalistId: 'suggestions-list-' + Math.floor(Math.random() * 1000000),
            data_registro_bloqueio_inicio: '',
            data_registro_bloqueio_fim: '',
            data_registro_desbloqueio_inicio: '',
            data_registro_desbloqueio_fim: '',
            data_vencimento_inicio: '',
            data_vencimento_fim: '',
          },
          modal : {
            logRecebivel: '',
            detalhesComissao: [],
            detalhesComissaoPaginacao: [],
            totalComissoes: {
              total: '',
              pago: ''
            }
          },
          gatewaysPagamento: [],
          orderBy: {
            plataforma: 0, 
            vencimento: 0, 
            recebimento: 0, 
            dias_de_atraso: 0, 
            invoice: 0, 
            parcela: 0, 
            aluno: 0, 
            valor_parcela: 0, 
            valor_recebido: 0, 
            status_bloqueio: 0, 
            status_pagamento: 0, 
            boleto: 0, 
          },
          selectedItems: [],
          selectAll: false
        },
        fastInvoiceFiltrosDetalhes: {
          idPlataforma : '',
          idInvoice : '',
          parcela : '',
        },
        fastPaginacao: {
          fastPageIndex: 1,
          fastPageSize: 10,
          fastTotalResults: "",
          fastPagInicio: "",
          fastPagFinal: "",
        },
        tableData : [],
      };
    },
    mounted: function () {
      if (this.$route.params.id_plataforma) {
        this.getFastDependecias(this.$route.params.id_plataforma, 'tesouraria')
          .then(() => {
            if (
              this.$store.state.fastPlataformaModulos.modulo_secretaria
            ) {            
              this.$store.state.fastCarregando = false;
              this.getFastHoraServidor()
              .then(() => {
                this.getPlataformasFilhas()
                this.getRegInadimplenciaRel(this.$route.params.id_plataforma)
              })
  
            } else {
              this.$store.state.fastCarregando = false;
              this.exibeToasty("Módulo não disponível para sua plataforma", "error");
            }
          })
          .catch((e) => {
            this.exibeToasty(e, "error");
            this.$store.state.fastCarregando = false;
          });
      }
    },
    computed: {
      totalParcelaSelected() {
        return this.fastRegInadimplenciaRel.filtro.reduce((total, item) => {
          if (!this.fastRegInadimplenciaRel.selectedItems.length){
            return total + item.valor_parcela
          }
          if (this.fastRegInadimplenciaRel.selectedItems.includes(item.id_invoice + "-" + item.parcela)) {
            return total + item.valor_parcela
          } else {
            return total
          }
        }, 0)
      },
      totalParcelaPagoSelected() {
        return this.fastRegInadimplenciaRel.filtro.reduce((total, item) => {
          if (!this.fastRegInadimplenciaRel.selectedItems.length){
            return total + item.valor_pago
          }
          if (this.fastRegInadimplenciaRel.selectedItems.includes(item.id_invoice + "-" + item.parcela)) {
            return total + item.valor_pago
          } else {
            return total
          }
        }, 0)
      }
    },
    watch: {
      'fastRegInadimplenciaRel.selectAll'(val) {
        if (val) {
          this.fastRegInadimplenciaRel.selectedItems = this.fastRegInadimplenciaRel.filtro.map((item) => item.id_invoice + "-" + item.parcela)
        } else {
          this.fastRegInadimplenciaRel.selectedItems = []
        }
      }
    },
    methods: {
      getPlataformasFilhas(){
      //Pega plataformas filhas para o filtro
      this.promiseGetFastApi("api/fast_plataforma/lista_filhas_novo", `id_plataforma=${this.$route.params.id_plataforma}`)
      .then((res) => {          
        if (res.Data.length) {
          this.fastRegInadimplenciaRel.filtros.plataformas = res.Data;
        } else{
          this.fastRegInadimplenciaRel.filtros.plataformas = this.$route.params.id_plataforma;
        }
      })
      .catch((e) => {
          console.log(e);
      });
      },
      async getRegInadimplenciaRel(){       
        this.fastRegInadimplenciaRel.loading = true

        let plataforma;
        let todasPlataformasFilhas;

        todasPlataformasFilhas = this.fastRegInadimplenciaRel.filtroEscolhido.id_plataforma === 'Todas';
        plataforma = todasPlataformasFilhas ? this.$route.params.id_plataforma : this.fastRegInadimplenciaRel.filtroEscolhido.id_plataforma;
        this.fastPaginacao.fastPageIndex = 1
        // this.promiseGetFastApi("api/fast_registro_inadimplencia/lista_alunos_bloqueados", `IdPlataforma=${plataforma}`)
        // &NomeAluno=${this.fastRegInadimplenciaRel.filtroEscolhido.nomeAluno}&NomeResponsavel=${this.fastRegInadimplenciaRel.filtroEscolhido.nomeResponsavel}&IdInvoice=${this.fastRegInadimplenciaRel.filtroEscolhido.idInvoice}&DataMatriculaStart=${this.fastRegInadimplenciaRel.filtroEscolhido.dataMatriculaStart}&DataMatriculaEnd=${this.fastRegInadimplenciaRel.filtroEscolhido.dataMatriculaEnd}&DataVencimentoStart=${this.fastRegInadimplenciaRel.filtroEscolhido.dataVencimentoStart}&DataVencimentoEnd=${this.fastRegInadimplenciaRel.filtroEscolhido.dataVencimentoEnd}&DataRecebimentoStart=${this.fastRegInadimplenciaRel.filtroEscolhido.dataRecebimentoStart}&DataRecebimentoEnd=${this.fastRegInadimplenciaRel.filtroEscolhido.dataRecebimentoEnd}&DataCancelamentoStart=${this.fastRegInadimplenciaRel.filtroEscolhido.dataCancelamentoStart}&DataCancelamentoEnd=${this.fastRegInadimplenciaRel.filtroEscolhido.dataCancelamentoEnd}&StatusVencido=${this.fastRegInadimplenciaRel.filtroEscolhido.statusVencido}&StatusPago=${this.fastRegInadimplenciaRel.filtroEscolhido.statusPago}&StatusPendente=${this.fastRegInadimplenciaRel.filtroEscolhido.statusPendente}&StatusCancelado=${this.fastRegInadimplenciaRel.filtroEscolhido.statusCancelado}&IdMatriculador=${this.fastRegInadimplenciaRel.filtroEscolhido.idMatriculador}
        this.promiseGetFastApi("api/fast_registro_inadimplencia/lista_alunos_bloqueados_pag", `PageIndex=${this.fastPaginacao.fastPageIndex}&PageSize=${this.fastPaginacao.fastPageSize}&IdPlataforma=${plataforma}&NomeAluno=${this.fastRegInadimplenciaRel.filtroEscolhido.nome_aluno}&IdPessoaAluno=${this.fastRegInadimplenciaRel.filtroEscolhido.id_pessoa_aluno}&NomeResponsavel=${this.fastRegInadimplenciaRel.filtroEscolhido.nome_responsavel}&IdInvoice=${this.fastRegInadimplenciaRel.filtroEscolhido.id_invoice}&DataRegistroBloqueioStart=${this.fastRegInadimplenciaRel.filtros.data_registro_bloqueio_inicio}&DataRegistroBloqueioEnd=${this.fastRegInadimplenciaRel.filtros.data_registro_bloqueio_fim}&DataRegistroDesbloqueioStart=${this.fastRegInadimplenciaRel.filtros.data_registro_desbloqueio_inicio}&DataRegistroDesbloqueioEnd=${this.fastRegInadimplenciaRel.filtros.data_registro_desbloqueio_fim}&DataVencimentoStart=${this.fastRegInadimplenciaRel.filtros.data_vencimento_inicio}&DataVencimentoEnd=${this.fastRegInadimplenciaRel.filtros.data_vencimento_fim}&StatusBloqueado=${this.fastRegInadimplenciaRel.filtros.status_bloqueio}`)
        .then(res => {
          if (res.Data.length) {
            this.fastRegInadimplenciaRel.filtro = todasPlataformasFilhas ? res.Data : res.Data.filter(item => item.id_plataforma == this.fastRegInadimplenciaRel.filtroEscolhido.id_plataforma);
            this.fastPaginacao.fastTotalResults = res.TotalResults
            this.fastPaginacao.fastPagInicio = res.RangeStart
            this.fastPaginacao.fastPagFinal = res.RangeEnd
          }
          else {
            this.fastRegInadimplenciaRel.filtro = []
            this.fastPaginacao.fastTotalResults = res.TotalResults
            this.fastPaginacao.fastPagInicio = res.RangeStart
            this.fastPaginacao.fastPagFinal = res.RangeEnd
          }
  
          this.fastRegInadimplenciaRel.loading = false
        }).catch(e => {
          console.log(e)
          this.exibeToasty(e, "error")
          this.fastRegInadimplenciaRel.loading = false
        })
      },
      pagRegInadimplenciaRel (){
        this.fastRegInadimplenciaRel.loading = true
        let plataforma;
        let todasPlataformasFilhas;
        todasPlataformasFilhas = this.fastRegInadimplenciaRel.filtroEscolhido.id_plataforma === 'Todas';
        plataforma = todasPlataformasFilhas ? this.$route.params.id_plataforma : this.fastRegInadimplenciaRel.filtroEscolhido.id_plataforma;
        this.$nextTick(() => {
          this.promiseGetFastApi("api/fast_registro_inadimplencia/lista_alunos_bloqueados_pag", `PageIndex=${this.fastPaginacao.fastPageIndex}&PageSize=${this.fastPaginacao.fastPageSize}&IdPlataforma=${plataforma}&NomeAluno=${this.fastRegInadimplenciaRel.filtroEscolhido.nome_aluno}&IdPessoaAluno=${this.fastRegInadimplenciaRel.filtroEscolhido.id_pessoa_aluno}&NomeResponsavel=${this.fastRegInadimplenciaRel.filtroEscolhido.nome_responsavel}&IdInvoice=${this.fastRegInadimplenciaRel.filtroEscolhido.id_invoice}&DataRegistroBloqueioStart=${this.fastRegInadimplenciaRel.filtros.data_registro_bloqueio_inicio}&DataRegistroBloqueioEnd=${this.fastRegInadimplenciaRel.filtros.data_registro_bloqueio_fim}&DataRegistroDesbloqueioStart=${this.fastRegInadimplenciaRel.filtros.data_registro_desbloqueio_inicio}&DataRegistroDesbloqueioEnd=${this.fastRegInadimplenciaRel.filtros.data_registro_desbloqueio_fim}&DataVencimentoStart=${this.fastRegInadimplenciaRel.filtros.data_vencimento_inicio}&DataVencimentoEnd=${this.fastRegInadimplenciaRel.filtros.data_vencimento_fim}&StatusBloqueado=${this.fastRegInadimplenciaRel.filtros.status_bloqueio}`)
          .then(res => {
            if (res.Data.length) {
              this.fastRegInadimplenciaRel.filtro = todasPlataformasFilhas ? res.Data : res.Data.filter(item => item.id_plataforma == this.fastRegInadimplenciaRel.filtroEscolhido.id_plataforma);
              this.fastPaginacao.fastTotalResults = res.TotalResults
              this.fastPaginacao.fastPagInicio = res.RangeStart
              this.fastPaginacao.fastPagFinal = res.RangeEnd
            }
            else {
              this.fastRegInadimplenciaRel.filtro = []
            }
            this.fastRegInadimplenciaRel.loading = false
          }).catch(e => {
            console.log(e)
            this.exibeToasty(e, "error")
            this.fastRegInadimplenciaRel.loading = false
          })
        })
      },
      alteraFiltroStatus(){
        if(this.fastRegInadimplenciaRel.filtros.status_bloqueio == 'true'){
          this.fastRegInadimplenciaRel.filtroEscolhido.acesso_bloqueado = true;
        }
        if(this.fastRegInadimplenciaRel.filtros.status_bloqueio == 'false'){
          this.fastRegInadimplenciaRel.filtroEscolhido.acesso_bloqueado = false;
        }
        if(!this.fastRegInadimplenciaRel.filtros.status_bloqueio){
          this.fastRegInadimplenciaRel.filtroEscolhido.acesso_bloqueado = '';
        }
      },
      filtraPessoaSugestao(filtro, listagem){
      if (this.fastRegInadimplenciaRel.filtros[filtro].length >= 3) {
        setTimeout(() => {
          this.promiseGetFastApi("api/fast_pessoa/lista_paginado", `PageIndex=1&PageSize=100&id_plataforma=${this.fastRegInadimplenciaRel.filtroEscolhido.id_plataforma}&nome_pessoa=${this.fastRegInadimplenciaRel.filtros[filtro]}&matriculador=true`)
          .then((res) => {          
            if (res.Data.length) {
              this.fastRegInadimplenciaRel.filtros[listagem] = res.Data;
              }
              console.log("listagem: " + JSON.stringify(this.fastRegInadimplenciaRel.filtros[listagem]))
            })
            .catch((e) => {
                console.log(e);
            });
          }, 1000)
          } else {
            this.fastRegInadimplenciaRel.filtros.usuariosGerou = []
        }
      },
      /*
      selecionaPessoaSugerida(usuario) {
          this.fastRegInadimplenciaRel.filtros.usuariosGerou = [];
          if(usuario == ''){
            this.fastRegInadimplenciaRel.filtroEscolhido.id_usuario_gerou = '';
            this.fastRegInadimplenciaRel.filtros.usuarioGerou = '';
          }else{
            this.fastRegInadimplenciaRel.filtros.usuarioGerou = usuario.nome_razao;
            this.fastRegInadimplenciaRel.filtroEscolhido.id_usuario_gerou = usuario.id_usuario;
          }
          console.log("id " + this.fastRegInadimplenciaRel.filtros.usuarioGerou)
      },
      */
      limpaFiltros(){
        this.fastRegInadimplenciaRel.filtroEscolhido.id_plataforma = this.$route.params.id_plataforma
        this.fastRegInadimplenciaRel.filtroEscolhido.nome_aluno = ''
        this.fastRegInadimplenciaRel.filtroEscolhido.nome_responsavel = ''
        this.fastRegInadimplenciaRel.filtroEscolhido.id_invoice = ''
        this.fastRegInadimplenciaRel.filtros.data_registro_bloqueio_inicio = ''
        this.fastRegInadimplenciaRel.filtros.data_registro_bloqueio_fim = ''
        this.fastRegInadimplenciaRel.filtros.data_registro_desbloqueio_inicio = ''
        this.fastRegInadimplenciaRel.filtros.data_registro_desbloqueio_fim = ''
        this.fastRegInadimplenciaRel.filtros.data_vencimento_inicio = ''
        this.fastRegInadimplenciaRel.filtros.data_vencimento_fim = ''
        this.fastRegInadimplenciaRel.filtros.status_bloqueio = ''
        this.fastRegInadimplenciaRel.filtros.usuarioGerou = ''
        this.fastRegInadimplenciaRel.filtros.usuarioCancelou =  ''
        this.fastRegInadimplenciaRel.filtros.usuariosGerou = []
        this.fastRegInadimplenciaRel.filtros.usuariosCancelou = []

        this.getRegInadimplenciaRel();
      },
      calculaDiasAtraso(dataVenc){
        const dataAtual = new Date()
        const dataCalc = new Date(dataVenc)

        const difMs = dataAtual - dataCalc
        const difDias = difMs / 86400000 - 1

        return difDias.toFixed();
      },
      exportaPlanilha(){
        const table = document.querySelector('#relatorio');
        const tableRows = table.querySelectorAll('tr');
        const tableData = [];

        for (let i = 0; i < tableRows.length; i++) {
          const tableCells = tableRows[i].querySelectorAll('td');
          const rowData = [];

          for (let j = 0; j < tableCells.length; j++) {
            rowData.push(tableCells[j].innerText);
          }

          tableData.push(rowData);
        }

        tableData[0] = ['Vencimento', 'Recebimento', 'Dias de Atraso', 'Invoice', 'Parcela', 'Aluno', 'Valor Parcela', 'Valor Recebido', 'Status Bloq.', 'Status Pgto.', 'Boleto', 'Log'];

        const worksheet = XLSX.utils.aoa_to_sheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'FastEAD - relatorio-contas-a-receber.xlsx');
      },
      exibeModal(el, modal){
        if(modal === 'log_pagto'){
          this.fastRegInadimplenciaRel.modal.logRecebivel = el.log_pagto_auditoria
          this.showModal('modalVerLog')
        } 
        // else if (modal === 'comissoes'){
          
        //   this.promiseGetFastApi("api/fast_tesouraria_invoice_parcela/lista_contas_receber_comissao", `IdPlataforma=${el.id_plataforma}&IdInvoice=${el.id_invoice}`)

        //   .then((res) => {   

        //       console.log('getComissoes', res)

        //       this.fastRegInadimplenciaRel.modal.detalhesComissao = res
              
        //       this.fastRegInadimplenciaRel.modal.totalComissoes.total =  this.fastRegInadimplenciaRel.modal.detalhesComissao.reduce((total, item) => total + Number(item.valorComissao), 0)
        //       this.fastRegInadimplenciaRel.modal.totalComissoes.pago =  this.fastRegInadimplenciaRel.modal.detalhesComissao.reduce((total, item) =>  {
        //         if (item.pago) {
        //           return total + Number(item.valorComissao);
        //         } else {
        //           return total;
        //         }
        //       }, 0)

        //       this.showModal('modalDetalhesComissao')
              
        //     })
        //     .catch((e) => {
        //         console.log(e);
        //     });
        // }
      },
      exibeDetalhes(e){
        this.fastInvoiceFiltrosDetalhes.idPlataforma = Number(this.fastRegInadimplenciaRel.filtroEscolhido.id_plataforma)
        this.fastInvoiceFiltrosDetalhes.idInvoice = e.id_invoice
        this.fastInvoiceFiltrosDetalhes.parcela = e.parcela

        this.alteraFastNavegacao('exibirDetalhes')
      },
      copyURLBoleto(link) {
      const el = document.createElement('textarea');  
      el.value = link;                                 
      el.setAttribute('readonly', '');                
      el.style.position = 'absolute';                     
      el.style.left = '-9999px';                      
      document.body.appendChild(el);                  
      const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;                                    
      el.select();                                    
      document.execCommand('copy');                   
      document.body.removeChild(el);                  
      if (selected) {                                 
        document.getSelection().removeAllRanges();    
        document.getSelection().addRange(selected);   
      }
      },
      orderByColumn(coluna) {
        Object.keys(this.fastRegInadimplenciaRel.orderBy).forEach(key => {
          if (key !== coluna) {
            this.fastRegInadimplenciaRel.orderBy[key] = 0;
          }
        });
        // Atualiza a ordem de classificação para a coluna clicada
        this.fastRegInadimplenciaRel.orderBy[coluna] = this.fastRegInadimplenciaRel.orderBy[coluna] === 1 ? -1 : 1;

        // Classifica a tabela de acordo com as colunas ordenadas e a ordem de classificação
        this.fastRegInadimplenciaRel.filtro.sort((a, b) => {
          const order = this.fastRegInadimplenciaRel.orderBy[coluna];

          if (coluna === 'plataforma') {
            return (a.plataforma > b.plataforma ? 1 : -1) * order; 
          } else if (coluna === 'vencimento') {
            return (a.data_vencimento > b.data_vencimento ? 1 : -1) * order;
          } else if (coluna === 'recebimento') {
            function ordenarPorData(a, b, order) {
              // Verifica se ambos os valores são nulos ou iguais a "Sem data"
              if ((!a || a === 'Sem data') && (!b || b === 'Sem data')) {
                return 0;
              }

              // Verifica se apenas um dos valores é nulo ou "Sem data" e coloca por último
              if (!a || a === 'Sem data') {
                return 1 * order;
              }

              if (!b || b === 'Sem data') {
                return -1 * order;
              }

              // Ordena as datas restantes normalmente
              const dataA = new Date(a);
              const dataB = new Date(b);

              return (dataA > dataB ? 1 : -1) * order;
            }

            return ordenarPorData(a.data_pagamento, b.data_pagamento, order);
          } else if (coluna === "dias_de_atraso") {
              const aDias = this.calculaDiasAtraso(a.data_vencimento);
              const bDias = this.calculaDiasAtraso(b.data_vencimento);
              return (aDias - bDias) * order;
          } else if (coluna === 'invoice') {
            return (a.id_invoice > b.id_invoice ? 1 : -1) * order; 
          } else if (coluna === 'aluno') {
            return a.nome_aluno.localeCompare(b.nome_aluno) * order;
          } else if (coluna === 'valor_parcela') {
            return (a.valor_parcela - b.valor_parcela) * order;
          } else if (coluna === 'valor_recebido') {
            return (a.valor_pago - b.valor_pago) * order;
          } else if (coluna === 'status_bloqueio') {
            return (a.acesso_bloqueado - b.acesso_bloqueado) * order;
          } else if (coluna === 'status_pagamento') {
              if (a.cancelado) return -1 * order;
              if (b.cancelado) return 1 * order;
              if (a.pago && !b.pago) return -1 * order;
              if (!a.pago && b.pago) return 1 * order;
              if (new Date(a.data_vencimento) < new Date() && new Date(b.data_vencimento) >= new Date()) return -1 * order;
              if (new Date(b.data_vencimento) < new Date() && new Date(a.data_vencimento) >= new Date()) return 1 * order;
              return 0;
            } else {
              return 0;
          }
        });
      },
      validaDataFiltro(inicio, fim, infErro){
        if(inicio === '' || fim === ''){
          return true
        } else if(inicio > fim){
          if (infErro) this.exibeToasty('A data inicial não pode ser maior que a data final', 'error');
          return false
        }
        return true;
      },
      criaTooltip(e){

        return `<div class="text-left">
                  Aluno: ${e.nome_aluno}<br>
                  Resp.: ${e.nome_responsavel}<br>
                  Data Vencimento: ${this.formataDataT(e.data_vencimento)}<br>
                  Valor: R$ ${this.formataPreco(e.valor_parcela)}<br>
                  Valor Pago: R$ ${e.valor_pago ? this.formataPreco(e.valor_pago) : this.formataPreco(0)}
                </div>`;
      }
    },
  };
  </script>
  
  <style scope>
  .container{
    max-width: 1280px;
  }
  .sugestao-lista {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #d8d8d8;
    border-top: none;
    padding: 0;
    margin: 0;
    z-index: 999;
  }
  .sugestao-item {
    list-style-type: none;
    padding: 3px 6px;
    cursor: pointer;
    color: rgb(95, 95, 95);
  }
  .sugestao-item:hover {
    transition: 0.3s;
    background-color: #e8e8e8;
  }
  .date-picker-filtro  {
    text-align: center;
  }
  .btn-custom-primary{
    background-color: #007bff!important;
    color: #fff!important;
  }
  .btn-custom-outline-primary{
    background-color: #fff!important;
    color: #007bff!important;
    border:1px solid #007bff!important;
  }
  .dropdown-menu{
    font-size: 12px;
  }

  </style>
  